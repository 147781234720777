import React from 'react';
import { Button, Card, Label, TextInput } from 'flowbite-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setUser } from "../store/reducers/authSlice";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Password too short').required('Required'),
});



export default function Login() {
  const dispatch = useDispatch();

const handleSubmit = async (values, navigate) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/token/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFTOKEN': `${process.env.REACT_APP_CSRF_TOKEN}`,
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    });
   

    const data = await response.json();
    if (response.ok) {
      toast.success('Sign in successful!');
      localStorage.setItem('accessToken', data.access);
    
      dispatch(setUser(data));
     
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } else {
      toast.error(data.detail || 'An error occurred during sign in');
      // throw new Error(data.detail || 'An error occurred during sign in');
    }
  } catch (error) {
    console.error('Error:', error.message);
    toast.error(error.message);
  }
};
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

 
   
React.useEffect(() => {
  if (token) {
    navigate("/");
  } else {
  }
}, [token, navigate]);

  const handleSignUpClick = () => {
    navigate('/sign-up');
  };





  return (
    
    <>
      <ToastContainer />
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'gray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card style={{ width: '30%', height: '50%' }}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={(values) => handleSubmit(values, navigate)}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col gap-4">
                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '24px' }}>Sign in</h1>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email">Your email</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="name@flowbite.com"
                    className={errors.email && touched.email ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="password">Your password</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="password"
                    name="password"
                    type="password"
                    className={errors.password && touched.password ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="password" component="div" className="text-red-500" />
                </div>
                <div className="flex items-center gap-2">
                  <Label htmlFor="remember">Not have an account?</Label>
                  <span
                    onClick={handleSignUpClick}
                    className="cursor-pointer text-blue-500 hover:underline"
                  >
                    Sign up
                  </span>
                </div>
                <Button type="submit">Submit</Button>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
}
