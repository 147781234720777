import { Select } from 'flowbite-react';
import React from 'react';

const SelectInput = ({ label, id, required, options, ...props }) => {
  return (
    <div>
      <div className="mb-2 block">
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {label}
        </label>
      </div>
      <Select id={id} required={required} {...props}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default SelectInput;
