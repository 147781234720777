import App from "../App";
import FileUploadPage from "../components/FileUploadPage";
import Login from "../components/Login";
import SignUpPage from "../components/SignUpPage";

const mainRoutes = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "upload",
        element: <FileUploadPage />,
  },
  {
    path: "sign-in",
        element: <Login />,
  },
  {
    path: "sign-up",
        element: <SignUpPage />,
  },
];

export default mainRoutes;