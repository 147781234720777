// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { api } from './api';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authSlice from './reducers/authSlice';
import searchSlice from './reducers/searchSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
};

const searchPersistConfig = {
  key: 'search',
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);

const persistedSearchReducer = persistReducer(searchPersistConfig, searchSlice);

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: persistedAuthReducer,
  search: persistedSearchReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

export default store;
