import React from 'react';
import { Button, Card, Label, TextInput } from 'flowbite-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { getNames } from 'country-list';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  dob: Yup.date().required('Required'),
  country: Yup.string().required('Required'),
  age: Yup.number().min(18, 'You must be at least 18 years old').required('Required'),
  occupation: Yup.string().required('Required'),
  password: Yup.string().required('Required').min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const countries = getNames().map((country) => ({ value: country, label: country }));

const handleSubmit = async (values, navigate) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/register/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFTOKEN': `${process.env.REACT_APP_CSRF_TOKEN}`,
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
        password2: values.confirmPassword,
        dob: values.dob,
        country: values.country,
        age: parseInt(values.age),
        occupation: values.occupation,
      }),
    });

    // const data = await response.json();
    // console.log(data); 
    if (response.ok) {
      toast.success('Registration successful!');
      setTimeout(() => {
        navigate('/sign-in'); 
      }, 2000); 
    } else {
      const errorData = await response.json();
      throw new Error(errorData.detail);
    }
  } catch (error) {
    console.error('Error:', error.message);
    toast.error(error.message);
  }
};

export default function SignUpPage() {
  const navigate = useNavigate(); 

  const handleSignInClick = () => {
    navigate('/sign-in');
  };


  return (
    <>
     
      <ToastContainer />
      <div
        style={{
          width: '100vw',
          height: 'auto',
          backgroundColor: 'gray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '52px'
        }}
      >
        <Card style={{ width: '30%', height: 'auto',  }}>
          <Formik
            initialValues={{ email: '', name: '', dob: '', country: '', age: '', occupation: '', remember: false, password: '', confirmPassword: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values) => handleSubmit(values, navigate)} 
          >
            {({ errors, touched, setFieldValue  }) => (
              <Form className="flex flex-col gap-4">
                  <h1 style={{display: 'flex', justifyContent: 'center', fontSize: '24px'}}>Sign up </h1>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email">Your email</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="name@flowbite.com"
                    className={errors.email && touched.email ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="password">Password</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="password"
                    name="password"
                    type="password"
                    className={errors.password && touched.password ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="password" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="confirmPassword">Confirm Password</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    className={errors.confirmPassword && touched.confirmPassword ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="confirmPassword" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="dob">Your date of birth</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="dob"
                    name="dob"
                    type="date"
                    className={errors.dob && touched.dob ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="dob" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="country">Your country</Label>
                  </div>
                  <Select
                    id="country"
                    name="country"
                    options={countries}
                    onChange={(option) => setFieldValue('country', option.value)}
                    className={errors.country && touched.country ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="country" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="age">Your age</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="age"
                    name="age"
                    type="number"
                    className={errors.age && touched.age ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="age" component="div" className="text-red-500" />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="occupation">Your occupation</Label>
                  </div>
                  <Field
                    as={TextInput}
                    id="occupation"
                    name="occupation"
                    type="text"
                    className={errors.occupation && touched.occupation ? 'border-red-500' : ''}
                  />
                  <ErrorMessage name="occupation" component="div" className="text-red-500" />
                </div>
                <div className="flex items-center gap-2">
      <Label htmlFor="remember">Already have an account?</Label>
      <span 
        onClick={handleSignInClick} 
        className="cursor-pointer text-blue-500 hover:underline"
      >
        Sign in
      </span>
    </div>
                <Button type="submit">Submit</Button>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
}
