import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  results: null,
  operators: {},  // Added this line
  searchAfter: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchResults(state, action) {
      state.results = action.payload.results;
      state.operators = action.payload.operators;
      state.searchAfter = action.payload.searchAfter
    },
    clearSearchResults(state) {
      state.results = null;
      state.operators = {};  // Added this line
      state.searchAfter = null
    },
  },
});

export const { setSearchResults, clearSearchResults } = searchSlice.actions;

export default searchSlice.reducer;
