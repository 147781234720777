import React from 'react';

const renderExcelTable = (data) => {
  const chunkSize = 100; // Define a chunk size for rendering
  const chunks = [];

  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize));
  }

  return (
    <div className="overflow-auto">
      {chunks.map((chunk, chunkIndex) => (
        <table className="table-auto w-full" key={chunkIndex}>
          <tbody>
            {chunk.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="border px-4 py-2 break-words">{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};

const ExcelViewer = ({ data }) => {
  return renderExcelTable(data);
};

export default ExcelViewer;
