import React, { useState, useEffect } from "react";
import "./App.css";
import { Button, Checkbox, Label, TextInput, Tooltip, Spinner } from "flowbite-react";
import SelectInput from "./components/common/SelectInput";
import NavbarComp from "./components/NavbarComp";
import parse from "html-react-parser";
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setSearchResults, clearSearchResults } from "./store/reducers/searchSlice";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const TableCell = ({ children }) => (
  <div className="flex flex-col min-w-[200px]">
    {children}
  </div>
);
const TextTableCell = ({ children }) => (
  <div className="flex flex-col w-full">
    {children}
  </div>
);

function App() {
  const initialFormState = {
    searchText: "",
    fuzzyTranspositions: false,
    searchTextType: "",
    booleanOperator: "",
    booleanOperatorAssociation: {},
    fuzziness: "",
    slop: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [searchTerms, setSearchTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(null)

  const dispatch = useDispatch();
  const searchResults = useSelector((state) => state.search.results);
  const operators = useSelector((state) => state.search.operators);
  const searchAfter = useSelector((state) => state.search.searchAfter);

  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        toast.warning("Please sign up first.");
        setTimeout(() => {
          navigate("/sign-up");
        }, 2000);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/token/verify/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,

          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error("Token verification failed");
        }
      } catch (error) {
        toast.warning("Session expired. Please sign in again.");
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          navigate("/sign-up");
        }, 2000);
      }
    };

    verifyToken();
  }, [token, navigate]);


  useEffect(() => {
    if (operators && Object.keys(operators).length > 0) {
      const parsedOperators = JSON.parse(operators);
      setFormData((prevState) => ({
        ...prevState,
        booleanOperatorAssociation: parsedOperators,
      }));
      setSearchTerms(Object.keys(parsedOperators));
    }
  }, [operators]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "searchTextType" && value === "STANDARD") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        slop: "",
      }));
    } else if (name === "searchTextType" && value === "EXACT") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        fuzziness: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitToServer();
  };

  const updateStateAndGetPayload = () => {
    const { searchText, booleanOperator } = formData;
    if (searchText.length === 0)
      return {
        fuzziness: formData?.fuzziness || "",
        match_type: formData?.searchTextType || "STANDARD",
        search_query:
          JSON.stringify(formData?.booleanOperatorAssociation) || {},
        slop: formData?.slop || "",
      };

    const operators = {
      ...formData.booleanOperatorAssociation,
      [searchText]: booleanOperator,
    };
    const searchWords = [...searchTerms, searchText];
    const payload = {
      fuzziness: formData?.fuzziness || "",
      match_type: formData?.searchTextType || "STANDARD",
      search_query: JSON.stringify(operators) || {},
      slop: formData?.slop || "",
    };

    setFormData((prevState) => ({
      ...prevState,
      searchText: "",
      booleanOperatorAssociation: operators,
    }));
    setSearchTerms(Array.from(new Set(searchWords)));

    return payload;
  };

  const submitToServer = async () => {
    const payload = updateStateAndGetPayload();
    const searchParams = new URLSearchParams({
      ...payload,
    }).toString();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/document_search/?${searchParams}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        let searchAfter = null

        if (data.hits.hits.length > 0 && data.hits.hits[data.hits.hits.length - 1]?.sort.length > 0) {
          searchAfter = data.hits.hits[data.hits.hits.length - 1]?.sort.join(',');
      }
      

        dispatch(setSearchResults({ results: data, operators: payload.search_query, searchAfter: searchAfter }));
      } else {
        if (response.status === 401) {
          localStorage.removeItem('accessToken');
          toast.warning('You have been logged out');
          setTimeout(() => {
            navigate('/sign-in');
          }, 2000);
        } else {
          console.error('Error fetching search results:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };


  const loadMoreResults = async () => {
    const payload = updateStateAndGetPayload();
    const searchParams = new URLSearchParams({
      ...payload,
      ...(searchAfter ? { search_after: searchAfter } : {}),
    }).toString();

    setLoadingMore(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/document_search/?${searchParams}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        const combinedResults = {
          ...searchResults,
          hits: {
            ...searchResults.hits,
            hits: [...searchResults.hits.hits, ...data.hits.hits],
          },
          operators: payload.search_query,
        };

        let searchAfter = null
        
        if (data.hits.hits.length > 0 && data.hits.hits[data.hits.hits.length - 1]?.sort.length > 0) {
          searchAfter = data.hits.hits[data.hits.hits.length - 1]?.sort.join(',');
      }

        dispatch(setSearchResults({results: combinedResults, operators: payload.search_query, searchAfter: searchAfter}));

      } else {
        if (response.status === 401) {
          localStorage.removeItem("accessToken");
          toast.warning("You have been logged out");
          setTimeout(() => {
            navigate("/sign-in");
          }, 2000);
        } else {
          console.error("Error fetching search results:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleLoadMore = () => {
    loadMoreResults();
  };

  const mustKeywords = [];
  const shouldKeywords = [];
  const notKeywords = [];

  for (const [term, operator] of Object.entries(
    formData?.booleanOperatorAssociation
  )) {
    if (operator === "MUST") {
      mustKeywords.push(term);
    } else if (operator === "SHOULD") {
      shouldKeywords.push(term);
    } else if (operator === "NOT") {
      notKeywords.push(term);
    }
  }

  const handleReset = () => {
    setFormData(initialFormState);
    dispatch(clearSearchResults());
    setSearchTerms([]);
  };

  const highlightText = (text) => {
    if (!text) return null;
    const joinedText = text.join(" ");
    const highlightedText = joinedText.replace(
      /<em>(.*?)<\/em>/g,
      '<span class="highlight">$1</span>'
    );
    return parse(highlightedText);
  };

  const handleSearchTermRemove = (indexToRemove) => {
    const searchedWords = [...searchTerms];
    const textToRemove = searchedWords.splice(indexToRemove, 1);
    if (textToRemove) {
      const updatedOperators = { ...formData.booleanOperatorAssociation };
      delete updatedOperators[textToRemove];
      setFormData((prevState) => ({
        ...prevState,
        booleanOperatorAssociation: updatedOperators,
      }));
    }
    setSearchTerms(searchedWords);
  };

  const handleCompressedFileClick = async (file_url) => {
    if (file_url) {
      window.open(file_url, "_blank");
    } else {
      console.error("Error fetching compressed file:", "No file found");
    }
  };

  const handleExportAsPDF = (hitId) => {
    console.log(`Export as DOC for ${hitId}`);
  };

  const handleExportAsTxt = (hitId) => {
    console.log(`Export as TXT for ${hitId}`);
  };

  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <NavbarComp />
      <div className="flex flex-col items-start w-full p-4">
        <div className="flex flex-row gap-4 w-full justify-center">
          <form
            className="flex flex-wrap gap-4 max-w-3 w-1/3"
            onSubmit={handleSubmit}
          >
            <TextTableCell className="w-full">
              <Label
                htmlFor="searchText"
                value="Search Text"
                className="mb-2"
              />
              <TextInput
                className="flex flex-row"
                id="searchText"
                name="searchText"
                placeholder="Search"
                value={formData.searchText}
                onChange={handleChange}
              />

              <ToastContainer />
            </TextTableCell>
            {formData?.searchText.length !== 0 && (
              <TableCell>
                <SelectInput
                  label="Boolean Operator"
                  id="sft_boolean_operator"
                  name="booleanOperator"
                  required
                  options={[
                    { label: "", value: "" },
                    { label: "MUST", value: "MUST" },
                    { label: "SHOULD", value: "SHOULD" },
                    { label: "NOT", value: "NOT" },
                  ]}
                  value={formData.booleanOperator}
                  onChange={handleChange}
                  className="w-full"
                />
              </TableCell>
            )}
            <div className="flex flex-row flex-wrap gap-2 w-full">
              {searchTerms?.map((term, index) => (
                <div className="flex items-center gap-2" key={index}>
                  <div className="flex items-center gap-2 bg-gray-200 text-gray-800 rounded-full px-3 py-1 shadow">
                    <span>{term}</span>
                    <button
                      type="button"
                      onClick={() => handleSearchTermRemove(index)}
                      className="text-gray-600 hover:text-gray-900"
                    >
                      <FaTimes />
                    </button>
                  </div>
                  <span key={term}>
                    {formData.booleanOperatorAssociation[term]}
                  </span>
                </div>
              ))}
            </div>

            <div className="col-span-2 flex w-full items-center mt-4 gap-4">
              <Button
                type="button"
                className=""
                onClick={() => submitToServer()}
                disabled={loading}
              >
                {loading ? (
                  <span>
                    <Spinner size="sm" light={true} />
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
              <Button
                color="dark"
                type="reset"
                className=""
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                color="warning"
                className="whitespace-nowrap"
                onClick={handleReset}
              >
                ReIndex Data
              </Button>
            </div>
          </form>
          <div className="mt-[28px]" >
            <Button onClick={toggleFilters} className="w-64">
              Advance Search
            </Button>

            {showFilters && (
              <div className="pt-5 border border-gray-300 rounded-lg shadow-md p-4 bg-white absolute w-64">
                <TableCell>
                  <SelectInput
                    label="Search Type"
                    id="searchTextType"
                    name="searchTextType"
                    required
                    options={[
                      { label: "Standard Full Text", value: "STANDARD" },
                      { label: "Exact Match", value: "EXACT" },
                    ]}
                    value={formData.searchTextType}
                    onChange={handleChange}
                    className="w-full"
                  />
                </TableCell>

                {formData.searchTextType === "STANDARD" && (
                  <TableCell>
                    <Tooltip
                      content="Fuzziness allows the search to be tolerant of spelling errors or minor differences. AUTO lets Elasticsearch decide the appropriate fuzziness level based on the term length."
                      placement="right"
                      theme={{
                        target: "",

                        base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm w-full",
                      }}
                    >
                      <SelectInput
                        label="Fuzziness"
                        id="sft_fuzziness"
                        name="fuzziness"
                        required
                        options={[
                          { label: "AUTO", value: "AUTO" },
                          { label: "0", value: "0" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                        ]}
                        value={formData.fuzziness}
                        onChange={handleChange}
                        className="w-full"
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {formData?.searchTextType === "EXACT" && (
                  <TableCell>
                    <Tooltip
                      content="Slop allows for a specified number of words to appear between the words in the query phrase, making the search less strict about the exact word order."
                      placement="right"
                      theme={{
                        target: "",

                        base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm w-full",
                      }}
                    >
                      <SelectInput
                        label="Slop"
                        id="slop"
                        name="slop"
                        required
                        options={[
                          { label: "0", value: "0" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                        ]}
                        value={formData.slop}
                        onChange={handleChange}
                        className="w-full"
                      />
                    </Tooltip>
                  </TableCell>
                )}

                <div className="col-span-2 flex items-center mt-3">
                  <Checkbox
                    id="sft_fuzzy_transpositions"
                    name="fuzzyTranspositions"
                    checked={formData.fuzzyTranspositions}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <Tooltip
                    content="Fuzzy transposition is a phenomenon where letters in a word are swapped or misplaced, but the overall word remains relatively understandable to a reader."
                    placement="right"
                    theme={{
                      target: "",

                      base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm w-full",
                    }}
                  >
                    <Label htmlFor="sft_fuzzy_transpositions">
                      Fuzzy Transpositions
                    </Label>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        {searchResults && (
          <div className="mt-28 w-full">
            <div className="p-5 m-2 flex justify-center flex-col items-center">
              <div>
                <h2 className="text-[#0E7490] text-left">
                  Results For: Query =
                </h2>
                {mustKeywords.length > 0 && (
                  <p className="text-teal-700 text-left">
                    Must Keywords: {mustKeywords.join(", ")}
                  </p>
                )}
                {shouldKeywords.length > 0 && (
                  <p className="text-teal-700 text-left">
                    Should Keywords: {shouldKeywords.join(", ")}
                  </p>
                )}
                {notKeywords.length > 0 && (
                  <p className="text-teal-700 text-left">
                    Not Keywords: {notKeywords.join(", ")}
                  </p>
                )}
              </div>
            </div>

            <h2 className="text-blue-500">
              [found {searchResults?.hits?.hits?.length} results]
            </h2>
            {searchResults?.hits?.hits?.map((hit, index) => (
              <div className="row-search-result-wrapper p-4" key={hit._id}>
                <div className="flex justify-between items-center mb-2">
                  <a
                    className="text-blue-500 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCompressedFileClick(hit._file_url);
                    }}
                  >
                    {hit._source["name"]} (Page: {hit._source["page_num"]})
                    <span className="ml-2 text-[10px] text-blue-500">
                      Score: {hit._score}
                    </span>
                  </a>
                  <div className="flex gap-2">
                    <Button size="sm" color="info" className="mr-2" onClick={() => handleExportAsPDF(hit._id)}>Export as PDF</Button>
                    <Button size="sm" color="info" onClick={() => handleExportAsTxt(hit._id)}>Export as TXT</Button>
                  </div>
                </div>
                <div className="search-row-subheading">
                  {highlightText(hit?.["highlight"]?.["text"]) ||
                    hit._source["text"]}
                </div>
              </div>
            ))}
            {
              searchAfter &&
              <div className="w-full flex items-center justify-center mt-[10px]">
                <Button size="sm" color="info" className="mr-2 flex w-[110px]" onClick={handleLoadMore}>{
                  loadingMore ?
                    <span>
                      <Spinner size="sm" light={true} />
                    </span>
                    :
                    "Load More"}
                </Button>
              </div>

            }
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
