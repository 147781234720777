import React, { useState, useEffect } from 'react';
import { Button, Label, Select, Spinner } from "flowbite-react";
import FileDropzone from './FileDropzone';
import NavbarComp from './NavbarComp';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { renderAsync } from 'docx-preview';
import * as XLSX from 'xlsx';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import PdfViewer from './common/viewers/PdfViewer';
import ExcelViewer from './common/viewers/ExcelViewer';
import DocxViewer from './common/viewers/DocxViewer';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const FileUploadPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fileContent, setFileContent] = useState(null);

  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!token) {
      toast.error("Please sign in first.");
      setTimeout(() => {
        navigate("/sign-in");
      }, 2000);
    }
  }, [token, navigate]);

  const handleChange = async (e) => {
    const files = Array.from(e.target.files).filter(file => {
      const allowedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'image/jpeg',
        'image/png',
        'image/jpg',
      ];
      return allowedTypes.includes(file.type);
    });
    setSelectedFiles(files);
    setSelectedFileIndex(0);
    setNumPages(null);
    setPageNumber(1);
    if (files.length > 0) {
      await renderFile(files[0]);
    }
  };

  const renderFile = async (file) => {
    const fileType = file.type;
    setLoading(true);
    if (fileType === 'application/pdf') {
      setFileContent(null);
    } else if (fileType === 'text/plain') {
      const text = await file.text();
      setFileContent(<pre className="whitespace-pre-wrap">{text}</pre>);
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      const arrayBuffer = await file.arrayBuffer();
      const docxContainer = document.createElement('div');
      await renderAsync(arrayBuffer, docxContainer);
      setFileContent(<DocxViewer htmlContent={docxContainer.innerHTML} />);
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setFileContent(<ExcelViewer data={json} />);
    } else if (fileType.startsWith('image/')) {
      const imageUrl = URL.createObjectURL(file);
      setFileContent(<img src={imageUrl} alt={file.name} className="max-w-full h-auto" />);
    }
    setLoading(false);
  };

  const handleFileSelectChange = async (e) => {
    const index = parseInt(e.target.value, 10);
    setSelectedFileIndex(index);
    setNumPages(null);
    setPageNumber(1);
    await renderFile(selectedFiles[index]);
  };

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      const formDataToSend = new FormData();
      selectedFiles.forEach(file => {
        formDataToSend.append('files', file);
      });

      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ocr/`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'X-CSRFTOKEN': process.env.REACT_APP_CSRF_TOKEN,
            Authorization: `Bearer ${token}`,
          },
          body: formDataToSend,
        });

        if (response.ok) {
          toast.success('OCR upload successful!');
        } else {
          if (response.status === 401) {
            localStorage.removeItem("accessToken");
            toast.error("You have been logged out");
            setTimeout(() => {
              navigate("/sign-in");
            }, 2000);
          } else {
            console.error('Error uploading files for OCR:', response.statusText);
          }
        }
      } catch (error) {
        toast.error('Error uploading files for OCR.');
        console.error('Error uploading files for OCR:', error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('No files selected');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handlePageChange = (newPageNumber, newNumPages = numPages) => {
    setPageNumber(newPageNumber);
    setNumPages(newNumPages);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Toaster position="top-right" reverseOrder={false} />
      <NavbarComp active="upload" />
      <div className="flex flex-col items-start w-full p-4">
        <FileDropzone onFileChange={handleChange} disabled={loading} accept=".pdf, .docx, .xlsx, .txt, .jpg, .jpeg, .png" />
        {selectedFiles.length > 0 && (
          <div className="flex flex-col w-full items-center mt-4">
            <Button onClick={handleUpload} className="mb-4">
              {loading ? <Spinner size="sm" light={true} /> : 'Upload to BE'}
            </Button>
            <Label htmlFor="fileSelect" value="Select File to View" className="mb-2" />
            <Select
              id="fileSelect"
              name="fileSelect"
              value={selectedFileIndex}
              onChange={handleFileSelectChange}
              className="mb-4"
              disabled={loading}
            >
              {selectedFiles.map((file, index) => (
                <option key={index} value={index}>
                  {file.name}
                </option>
              ))}
            </Select>
            <div className="flex flex-col w-full items-center mt-4">
              {loading && (
                <div className="absolute z-10 flex justify-center items-center w-full h-full bg-gray-100 bg-opacity-75">
                  <Spinner size="lg" />
                </div>
              )}
              {selectedFiles[selectedFileIndex].type === 'application/pdf' ? (
                <PdfViewer
                  file={selectedFiles[selectedFileIndex]}
                  pageNumber={pageNumber}
                  numPages={numPages}
                  onPageChange={handlePageChange}
                />
              ) : (
                <div className="w-full mt-4">
                  {fileContent}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploadPage;
