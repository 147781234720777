import React from 'react';
import { Document, Page } from 'react-pdf';
import { Button } from "flowbite-react";

const PdfViewer = ({ file, pageNumber, numPages, onPageChange }) => {
  return (
    <>
      <p>Page {pageNumber} of {numPages}</p>
      <div className="relative w-full flex justify-between items-center">
        <Button onClick={() => onPageChange(pageNumber - 1)} disabled={pageNumber <= 1}>
          Previous
        </Button>
        <Document file={file} onLoadSuccess={({ numPages }) => onPageChange(1, numPages)} className="mt-4">
          <Page pageNumber={pageNumber} />
        </Document>
        <Button onClick={() => onPageChange(pageNumber + 1)} disabled={pageNumber >= numPages}>
          Next
        </Button>
      </div>
    </>
  );
};

export default PdfViewer;
