import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarCollapse,
  NavbarLink,
  NavbarToggle,
} from "flowbite-react";
import { FiLogOut } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export function NavbarComp({ active = "" }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("Successfully logged out");
    setTimeout(() => {
      navigate("/sign-in");
    }, 2000);
  };

  return (
    <>
      <Navbar fluid rounded>
        <NavbarBrand href="">
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Karam Shaar
          </span>
        </NavbarBrand>
        <NavbarToggle />
        <NavbarCollapse>
          <NavbarLink href="/" active={!active && true}>
            Home
          </NavbarLink>
          <NavbarLink href="upload" active={active === "upload" && true}>
            Upload Document
          </NavbarLink>

          <FiLogOut
            size={25}
            onClick={handleLogout}
            className="mr-2"
            style={{ cursor: "pointer" }}
          />
        </NavbarCollapse>
      </Navbar>
      <ToastContainer />
    </>
  );
}

export default NavbarComp;



